jQuery(function () {
    const MOBILE_BREAKPOINT_WIDTH = 991;
    console.log("ready!");
    // Close out sub menu
    jQuery('.sub__close').click(function (e) {
        e.preventDefault();
        jQuery(this).parent().parent().parent().removeClass('focus');
    });

    jQuery('.dropdown-menu a').click(function (e) {
        if (jQuery(window).width() <= MOBILE_BREAKPOINT_WIDTH) {
            console.log('click');
            jQuery('.navbar-toggler').click();
        }
    });
});